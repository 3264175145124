import React, { Component } from 'react';
import { graphql } from 'gatsby';

import ArticleComponent from '../components/articles/article/Article';
import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import PageModel from '../models/Page';
import ProductModel from '../models/Product';
import { articleSchema } from './schemas';
import { getProductsForSlices } from '../utils/utils';

export const query = graphql`
  query ($id: String!, $productIdsAll: [Int]) {
    sanityPage(id: { eq: $id }) {
      _createdAt
      _updatedAt
      featuredMedia {
        fullHeight
        fullWidth
        image {
          asset {
            gatsbyImageData
          }
        }
        type
        video {
          imageUrl
          value
        }
        videoPickit {
          imageUrl
          value
        }
        videoType
        videoYoutube
      }
      headerContentOverlay
      ingress {
        languagePrimary
        languageSecondary
      }
      isLandingPage
      slices {
        ... on SanityCollectionSlice {
          ...CollectionSlice
        }
        ... on SanityCompetitionSlice {
          ...CompetitionSlice
        }
        ... on SanityEmailSignupSlice {
          ...EmailSignupSlice
        }
        ... on SanityFileSlice {
          ...FileSlice
        }
        ... on SanityMediaSlice {
          ...MediaSlice
        }
        ... on SanityProductsSlice {
          ...ProductsSlice
        }
        ... on SanityProductTextSlice {
          ...ProductTextSlice
        }
        ... on SanityPublicationSlice {
          ...PublicationSlice
        }
        ... on SanityQuoteSlice {
          ...QuoteSlice
        }
        ... on SanityRelatedArticleSlice {
          ...RelatedArticleSlice
        }
        ... on SanitySignupFormSlice {
          ...SignupFormSlice
        }
        ... on SanityTableSlice {
          ...TableSlice
        }
        ... on SanityTextSlice {
          ...TextSlice
        }
      }
      title {
        languagePrimary
        languageSecondary
      }
      uid {
        current
      }
    }
    allInRiverProduct(filter: { entityId: { in: $productIdsAll } }) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`;

class Page extends Component {
  render() {
    const { brand, site, path } = this.props;

    const { productIdsSlices, siteUID } = this.props.pageContext;
    const { allInRiverProduct, sanityPage } = this.props.data;

    const products = getProductsForSlices(
      productIdsSlices,
      allInRiverProduct,
      ProductModel,
      siteUID,
      site
    );
    const article = new PageModel(sanityPage, siteUID, products);

    return (
      <ArticleComponent
        article={article}
        breadcrumbs={breadcrumbs('PAGE', article)}
        jsonLd={jsonLd(brand, site, path, article)}
        site={site}
      />
    );
  }
}

export default Page;
